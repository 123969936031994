<template>
    <div class="fixed flex items-center justify-center popup" v-if="showPopup" @touchmove.stop.prevent="moveHandle">
        <div class="duration-500 mask" :class="conetentFlag ? 'show_mask' : ''" @click="closePopup"></div>
        <div class="relative p-2 duration-700 rounded popup_content"
            :class="conetentFlag ? 'show_conent' : 'hidden_content'" :style="{ width }">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isShow: {
            type: Boolean,
            default: false
        },
        width: {
            type: String,
            default: "95%"
        }
    },
    data() {
        return {
            showPopup: false,// 控组组件的显示隐藏
            conetentFlag: false,  // 控制mask的变量
        }
    },
    created() {
        this.showPopup = this.isShow
    },
    watch: {
        isShow: {
            handler: function (newVal) {
                if (newVal) {
                    this.showPopup = newVal
                    setTimeout(() => {
                        this.conetentFlag = newVal
                    }, 100)
                } else {
                    this.conetentFlag = newVal
                    setTimeout(() => {
                        this.showPopup = newVal
                    }, 500)
                }
            },
            deep: true
        }
    },
    methods: {
        closePopup() {
            this.$emit('close')
        },
        moveHandle() {
            return false
        }
    },

}
</script>

<style lang="scss" scoped>
.popup {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;
}

.mask {
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0);
}

.show_mask {
    background: rgba(0, 0, 0, 0.3);
}

.popup_content {
    // height: 300px;

    max-width: 56.25rem;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0 1px 5px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(13.5px);
    -webkit-backdrop-filter: blur(16.5px);
    border-bottom: 1px solid rgba(255, 255, 255, 0.18);
    z-index: 10;
    transform: translateY(-9.375rem);
    opacity: 0;
}

.show_conent {
    transform: translateY(0px);
    opacity: 1;
}
</style>
