<template>
    <div class="mb-4">
        <div class="flex items-center justify-between w-full duration-500 base_info border_box sm:p-4">
            <div class="img_box">
                <img v-if="getUserInfo.userPicture" :src="getUserInfo.userPicture" alt />
                <img v-else src="../../../../assets/images/personal/null_user.png" alt />
            </div>
            <div class="duration-500 base_right">
                <div class="flex justify-start">
                    <span class="text-gray-400 duration-500 text_left">用户名：</span>
                    <span class="span_text">{{ getUserInfo.userName ? getUserInfo.userName : getUserInfo.nickName }}</span>
                </div>
                <div class="flex items-center justify-between info_base">
                    <div>
                        <span class="text-gray-400 text_left">邮箱：</span>
                        <span class="duration-500 span_text">{{ getUserInfo.mail ? getUserInfo.mail : '未绑定' }}</span>
                    </div>
                    <div class="flex items-center text-gray-400 cursor-pointer" @click="goPage('/index/certification')">
                        实名认证
                        <img src="../../../../assets/images/personal/personal2.png" class="ml-2" alt style="width:6px" />
                    </div>
                </div>
                <div class="flex items-center justify-between">
                    <div>
                        <span class="text-gray-400 duration-500 text_left">手机号：</span>
                        <span>{{ getUserInfo.telphone ? getUserInfo.telphone : '未绑定' }}</span>
                    </div>
                    <div class="flex items-center text-gray-400 cursor-pointer" @click="goPage('/index/modify')">
                        修改个人信息
                        <img src="../../../../assets/images/personal/personal2.png" class="ml-2" alt style="width:6px" />
                    </div>
                </div>
            </div>
        </div>
        <div class="duration-500 sm:mt-4 box_margin">
            <div class="flex items-center justify-between w-full duration-500 table_title">
                <div>我的收藏</div>
                <div class="flex items-center text-gray-400 pc_flex_show" @click="goPage('/index/personal/collection')">
                    查看更多
                    <img src="../../../../assets/images/personal/personal2.png" class="ml-2" alt style="width:6px" />
                </div>
            </div>
            <div class="duration-500 border_box conent_box">
                <div class="flex flex-wrap items-center w-full" v-if="getCollection.wallpaper.length > 0">
                    <div style="width:33.33%" class="flex items-center justify-center"
                        v-for="(item, index) in getCollection.wallpaper" :class="index > 2 ? 'mt-3' : ''"
                        :key="item.wallpaperId">
                        <div style="width:95%">
                            <div class="relative w-full">
                                <!-- <img src="../../../../assets/images/personal/personal4.png" alt /> -->
                                <img :src="item.image" alt @click="review(item.image)" />
                                <div class="absolute z-10 icon_box">
                                    <div class="flex items-center cursor-pointer phone_flex_show">
                                        <img src="../../../../assets/images/serve/collection.png" alt
                                            @click="cannelUserCollection('wallpaper', item)" />
                                        <img src="../../../../assets/images/serve/download.png" alt
                                            @click="download(item.image)" />
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-wrap justify-between mt-2 pc_flex_show">
                                <el-button class="btn" @click="cannelUserCollection('wallpaper', item)">取消收藏</el-button>
                                <el-button type="primary" class="btn download"
                                    @click="download(item.image)">下载壁纸</el-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex items-center justify-center w-full text-gray-500 null" v-else>暂无收藏</div>
            </div>
        </div>
        <div class="duration-500 sm:mt-4 box_margin">
            <div class="flex items-center justify-between w-full duration-500 table_title">
                <div>我的设备</div>
                <!-- <div
                    class="flex items-center text-gray-400 pc_flex_show"
                    @click="goPage('/index/sales/equipment')"
                >
                    绑定其它设备
                    <img
                        src="../../../../assets/images/personal/personal2.png"
                        class="ml-2"
                        alt
                        style="width:6px"
                    />
                </div> -->
            </div>
            <div class="p-4 border_box conent_box">
                <div class="flex flex-wrap items-center w-full" v-if="getEquipment.length > 0">
                    <div style="width:25%" class="flex items-center justify-center" v-for="(item, index) in getEquipment"
                        :class="index > 3 ? 'mt-3' : ''" :key="item.equipmentId">
                        <div style="width:95%">
                            <div class="w-full">
                                <!-- <img :src="item.equipmentImage" alt v-if="item.equipmentImage" /> -->
                                <!-- src="../../../../assets/images/personal/personal8.png" -->
                                <img :src="logoPic.head" alt />
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="flex items-center justify-center w-full text-gray-500 null">未绑定相关设备</div>
            </div>
        </div>
        <Popup :isShow="isShow" @close="closeSearch">
            <template>
                <div>
                    <img :src="reviewImg" alt />
                </div>
            </template>
        </Popup>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Popup from "@/components/Popup/Popup"
export default {
    data() {
        return {
            isShow: false,
            reviewImg: ""
        };
    },
    components: {
        Popup
    },
    created() {
        this.$store.dispatch("aquireCollection", this.$EventBus)
        this.$store.dispatch("aquireEquipment", this.$EventBus)
    },
    computed: {
        ...mapState(['logoPic']),
        ...mapGetters(['getCollection', 'getEquipment', "getUserInfo", 'getCollection'])
    },
    methods: {
        goPage(path) {
            this.$router.push({ path })
        },
        // 取消收藏
        cannelUserCollection(type, item) {
            this.$store.dispatch("cannelUserCollection", { type, item, $EventBus: this.$EventBus })
        },
        // 壁纸下载
        download(url) {
            this.canvasDownload(url)
        },
        // 关闭弹窗
        closeSearch() {
            this.isShow = false
        },
        // 图片预览
        review(url) {
            this.isShow = true
            this.reviewImg = url
        }
    },
};
</script>

<style lang='scss' scoped>
.border_box {
    border: 0.0625rem solid var(--border-base);
}

@media screen and (max-width: 640px) {
    .img_box {
        width: 4rem;
    }

    .base_right {
        width: calc(100% - 5rem);
        font-size: 0.75rem;

        img {
            width: 0.375rem !important;
        }

        .info_base {
            margin: 0.3125rem 0;
        }
    }

    .base_info {
        padding: 0.625rem 0.25rem;
    }

    .table_title {
        background: white;
        padding: 0.5rem 0;
    }

    .conent_box {
        padding: 0;
        border: none !important;
    }

    .text_left {
        width: 3.125rem;
        display: inline-block;
        text-align: left;
    }
}

@media screen and (max-width: 900px) {
    .pc_flex_show {
        display: none;
    }

    .box_margin {
        margin-top: 0.625rem;
    }

    .icon_box {
        bottom: 0.25rem;
        right: 0.25rem;

        img {
            width: 0.75rem;
            margin-left: 0.3125rem;
        }
    }

    .phone_flex_show {
        display: flex;
    }
}

@media screen and (min-width: 640px) {
    .table_title {
        background: #e8e8e8;
        padding: 0.5rem 1rem;
    }

    .conent_box {
        padding: 1rem;
    }

    .img_box {
        width: 6.25rem;
    }

    .base_right {
        width: calc(100% - 8rem);
    }

    .info_base {
        margin: 1rem 0;
    }

    .text_left {
        width: 3.75rem;
        display: inline-block;
        text-align: left;
    }
}

@media screen and (min-width: 900px) {
    .pc_flex_show {
        display: flex;
    }

    .btn {
        width: 49%;
        margin: 0 !important;
    }

    .phone_flex_show {
        display: none;
    }
}
</style>